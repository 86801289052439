import { DataContextProvider } from './DataContext';
import Schedule from './components/Schedule';
import Templates from './components/Templates';

export default function App() {
    return (
        <DataContextProvider>
            <div className="container py-3 px-4 space-y-5">
                <Templates />
                <Schedule />
            </div>
        </DataContextProvider>
    );
}
