import type { FormEvent } from 'react';
import { useCallback, useEffect, useState } from 'react';

export default function useDebounceInput(
    init: string,
    update: (value: string) => void,
    validator?: (value: string) => boolean,
) {
    const [value, setValue] = useState(init);
    const [valid, setValid] = useState<boolean|null>(null);
    const onChange = useCallback((event: FormEvent<HTMLInputElement>) => {
        setValid(null);
        setValue(event.currentTarget.value);
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (validator) {
                if (validator(value)) {
                    setValid(true);
                    update(value);
                } else {
                    setValid(false);
                }
            } else {
                update(value);
            }
        }, 1000);
        return () => clearTimeout(delayDebounceFn);
    }, [value, validator]);

    return { value, onChange, valid };
}
