import type { FormEvent } from 'react';
import { useCallback, useState } from "react";
import { useDataContext } from "../DataContext";
import patternValidator from '../utils/patternValidator';

export default function AddTemplate() {
    const [pattern, setPattern] = useState('');
    const [command, setCommand] = useState('');
    const [error, setError] = useState(false);

    const onChangePattern = useCallback((event: FormEvent<HTMLInputElement>) => {
        setPattern(event.currentTarget.value);
        setError(false);
    }, []);
    const onChangeCommand = useCallback((event: FormEvent<HTMLInputElement>) => setCommand(event.currentTarget.value), []);

    const { addTemplate } = useDataContext();
    const save = useCallback(() => {
        if (patternValidator(pattern)) {
            addTemplate(pattern, command);
            setPattern('');
            setCommand('');
        } else {
            setError(true);
        }
    }, [pattern, command]);

    return (
        <tfoot>
            <tr className={error ? 'bg-red-100 text-red-900' : ''}>
                <td className="p-1">
                    <input
                        aria-label="Pattern"
                        type="text"
                        value={pattern}
                        onChange={onChangePattern}
                        className="border-0 rounded-sm bg-transparent min-w-full py-3 pl-3 pr-2 text-sm font-medium text-gray-900 font-mono sm:pl-5"
                        placeholder="* * * * *"
                    />
                </td>
                <td className="p-1">
                    <input
                        aria-label="Command"
                        type="text"
                        value={command}
                        onChange={onChangeCommand}
                        className="border-0 rounded-sm bg-transparent min-w-full px-2 py-3 text-sm text-gray-500 font-mono"
                        placeholder="~/checker.sh"
                    />
                </td>
                <td // eslint-disable-line jsx-a11y/control-has-associated-label
                />
                <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                    <button type="button" className="text-green-600 hover:text-green-900" onClick={save}>
                        Добавить
                    </button>
                </td>
            </tr>
        </tfoot>
    );
}
