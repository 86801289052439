import { useCallback, useEffect } from "react";
import { ITemplate, useDataContext } from "../DataContext";
import useTextInput from "../utils/useTextInput";
import patternValidator from "../utils/patternValidator";

interface IProps {
    template: ITemplate;
}

function classNames(...classes: Array<string | null>): string {
    return classes.filter(Boolean).join(' ');
  }

export default function Template({ template }: IProps) {
    const { updateTemplate, disableTemplate, enableTemplate, removeTemplate } = useDataContext();

    const [pattern, patternProps] = useTextInput(template.pattern, patternValidator);
    const [command, commandProps] = useTextInput(template.command);
    useEffect(() => {
        updateTemplate(template.uid, pattern, command);
    }, [template.uid, pattern, command]);

    const activeHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            enableTemplate(template.uid);
        } else {
            disableTemplate(template.uid);
        }
    }, [template.uid]);
    const removeHandler = useCallback(() => removeTemplate(template.uid), [template]);

    return (
        <tr className={patternProps.valid === false ? 'bg-red-100 text-red-900' : ''}>
            <td className="p-1">
                <input
                    aria-label="Pattern"
                    type="text"
                    value={patternProps.value}
                    onChange={patternProps.onChange}
                    className={classNames(
                        'border-0 rounded-sm bg-transparent min-w-full py-3 pl-3 pr-2 text-sm font-medium font-mono sm:pl-5',
                        patternProps.valid === false ? 'text-red-900 focus:ring-red-700' : 'text-gray-900 focus:ring-blue-500',
                    )}
                />
            </td>
            <td className="p-1">
                <input
                    aria-label="Command"
                    type="text"
                    value={commandProps.value}
                    onChange={commandProps.onChange}
                    className={classNames(
                        'border-0 rounded-sm bg-transparent min-w-full px-2 py-3 text-sm font-mono',
                        patternProps.valid === false ? 'text-red-900 focus:ring-red-700' : 'text-gray-900 focus:ring-blue-500',
                    )}
                />
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-center">
                <input
                    aria-label="Is Active?"
                    type="checkbox"
                    checked={template.active} onChange={activeHandler}
                    className="h-4 w-4 rounded border-gray-300 text-green-500 focus:ring-green-500"
                />
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                <button type="button" onClick={removeHandler} className="text-red-600 hover:text-red-900">
                    Удалить
                </button>
            </td>
        </tr>
    );
}
