import parser from 'cron-parser';

export default function patternValidator(pattern: string) {
    if (pattern.trim() === '') {
        return false;
    }

    try {
        parser.parseExpression(pattern);
        return true;
    } catch (e) {
        return false;
    }
}
