import cronParser from 'cron-parser';

import type { ITemplate } from '../DataContext';

export interface ISchedule {
    time: Date;
    command: string;
}

export function buildSchedule(
    template: ITemplate,
    startDate: Date,
    finalDate: Date,
) {
    const interval = cronParser.parseExpression(template.pattern, {
        currentDate: startDate,
        endDate: finalDate,
        iterator: true,
    });
    const schedule: ISchedule[] = [];
    while (interval.hasNext()) {
        schedule.push({
            time: interval.next().value.toDate(),
            command: template.command,
        });
    }
    return schedule;
}
