import type { ChangeEventHandler } from 'react';
import { useState } from 'react';
import useDebounceInput from './useDebounceInput';

export default function useTextInput(init: string | (() => string), validator?: (value: string) => boolean): [
    string,
    {
        value: string,
        onChange: ChangeEventHandler<HTMLInputElement>, 
        valid: boolean | null,
    },
] {
    const [value, setValue] = useState<string>(init);
    const inputProps = useDebounceInput(value, setValue, validator);
    return [value, inputProps];
}
