import { useDataContext } from "../DataContext";
import Template from "./Template";
import AddTemplate from "./AddTemplate";
import Import from "./Import";

export default function Templates() {
    const { templates } = useDataContext();
    return (
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                            <tr>
                                <th scope="col" className="w-[4rem] py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                                    Расписание
                                </th>
                                <th scope="col" className="w-[30rem] px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Задача
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Активно
                                </th>
                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                    <span className="sr-only">Удалить</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {templates.map(template => (
                                <Template
                                    key={template.uid}
                                    template={template}
                                />
                            ))}
                        </tbody>
                        <AddTemplate />
                        {templates.length === 0 ? <Import /> : null}
                    </table>
                </div>
            </div>
        </div>
    );
}
