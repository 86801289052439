import type { ChangeEvent } from "react";
import { Fragment, useCallback, useState } from "react";
import { Dialog, Transition } from '@headlessui/react';
import parser from 'cron-parser';
import { useDataContext } from "../DataContext";

export default function Import()
{
    const [isOpen, setIsOpen] = useState(false);
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);

    const [hasError, setHasError] = useState(false);

    const [fileContent, setFileContent] = useState('');
    const fileContentOnChange = useCallback((event: ChangeEvent<HTMLTextAreaElement>) => {
        setFileContent(event.currentTarget.value);
        setHasError(false);
    }, []);

    const { addTemplate } = useDataContext();

    const importHandler = useCallback(() => {
        try {
            const rules = fileContent.split('\n').map(row => {
                const [pattern, command] = row.split('\t', 2);
                parser.parseExpression(pattern);
                return [pattern, command];
            });
            rules.forEach(rule => addTemplate(rule[0], rule[1]));
            setIsOpen(false);
            setFileContent('');
        } catch(e) {
            setHasError(true);
        }
    }, [fileContent]);

    return (
        <tfoot>
            <tr>
                <td colSpan={4} className="relative whitespace-nowrap py-4 px-4 text-center text-sm font-medium sm:px-6">
                    <button type="button" className="text-indigo-600 hover:text-indigo-900" onClick={openModal}>
                        Импортировать
                    </button>
                    <Transition appear show={isOpen} as={Fragment}>
                        <Dialog as="div" className="relative z-10" onClose={closeModal}>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="fixed inset-0 bg-black/25" />
                            </Transition.Child>

                            <div className="fixed inset-0 overflow-y-auto">
                                <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-gray-900"
                                    >
                                        Импорт из файла
                                    </Dialog.Title>
                                    <p className="my-2 text-sm text-gray-500">
                                        В настоящий момент все строки должны содержать только правила, при этом шаблон и команда должны быть разделены символом табуляции.
                                    </p>
                                    {hasError ? (
                                        <p className="my-2 text-sm text-red-500">Не корректный формат</p>
                                    ) : null}
                                    <textarea
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                        onChange={fileContentOnChange}
                                        rows={10}
                                        defaultValue={fileContent}
                                    />

                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={importHandler}
                                        >
                                            Импорт
                                        </button>
                                    </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                                </div>
                            </div>
                        </Dialog>
                    </Transition>
                </td>
            </tr>
        </tfoot>
    );
}
