import { nanoid } from 'nanoid';
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useState,
} from 'react';

export interface ITemplate {
    uid: string;
    pattern: string;
    command: string;
    active: boolean;
}

export interface IDataContext {
    templates: Readonly<ITemplate[]>;
    addTemplate: (pattern: string, command: string, active?: boolean) => string;
    updateTemplate: (uid: string, pattern: string, command: string) => void;
    disableTemplate: (uid: string) => void;
    enableTemplate: (uid: string) => void;
    removeTemplate: (uid: string) => void;
    thresholdWarning: number;
    thresholdEmergin: number;
}

const DataContext = createContext<IDataContext>({
    templates: [],
    addTemplate: () => {
        throw new Error('Поведение не определено');
    },
    updateTemplate: () => {
        throw new Error('Поведение не определено');
    },
    disableTemplate: () => {
        throw new Error('Поведение не определено');
    },
    enableTemplate: () => {
        throw new Error('Поведение не определено');
    },
    removeTemplate: () => {
        throw new Error('Поведение не определено');
    },
    thresholdWarning: 1,
    thresholdEmergin: 2,
});

export const useDataContext = () => useContext(DataContext);

// eslint-disable-next-line react/function-component-definition
export const DataContextProvider = ({ children }: { children: ReactNode }) => {
    const [templates, setTemplates] = useState<ITemplate[]>([]);

    const addTemplate = useCallback(
        (pattern: string, command: string, active = true) => {
            const uid = nanoid();
            setTemplates(old => [...old, { uid, pattern, command, active }]);
            return uid;
        },
        [],
    );

    const updateTemplate = useCallback((uid: string, pattern: string, command: string) => {
        setTemplates(old => old.map(template => template.uid === uid ? { ...template, pattern, command } : template));
    }, []);

    const disableTemplate = useCallback((uid: string) => {
        setTemplates(old => old.map(template => template.uid === uid ? { ...template, active: false } : template));
    }, []);

    const enableTemplate = useCallback((uid: string) => {
        setTemplates(old => old.map(template => template.uid === uid ? { ...template, active: true } : template));
    }, []);

    const removeTemplate = useCallback((uid: string) => {
        setTemplates(old => old.filter(template => template.uid !== uid));
    }, []);

    const [thresholdWarning] = useState(1);
    const [thresholdEmergin] = useState(2);

    return (
        <DataContext.Provider
            // eslint-disable-next-line react/jsx-no-constructed-context-values
            value={{
                templates,
                addTemplate,
                updateTemplate,
                disableTemplate,
                enableTemplate,
                removeTemplate,
                thresholdWarning,
                thresholdEmergin,
            }}
        >
            {children}
        </DataContext.Provider>
    );
};
