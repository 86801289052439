import { useCallback, useMemo } from 'react';
import { useDataContext } from '../DataContext';
import { buildSchedule } from '../utils/buildSchedule';
import useDateInput from '../utils/useDateInput';

const timeFormat = (date: Date) => {
    const h = date.getUTCHours().toString().padStart(2, '0');
    const m = date.getUTCMinutes().toString().padStart(2, '0');
    const s = date.getUTCSeconds().toString().padStart(2, '0');
    return `${h}:${m}:${s}`;
};

const now = new Date();

export default function Schedule() {
    const { templates, thresholdWarning, thresholdEmergin } = useDataContext();

    const [startDate, startDateInputProps] = useDateInput(() => {
        now.setUTCHours(0, 0, 0, 0);
        return now;
    });

    const [finalDate, finalDateInputProps] = useDateInput(() => {
        now.setUTCHours(23, 59, 59, 999);
        return now;
    });

    const rows = useMemo(() => {
        const map = new Map<number, string[]>();
        templates.forEach(template => {
            if (!template.active) return;
            buildSchedule(template, startDate, finalDate).forEach(
                ({ time, command }) => {
                    const timeKey = time.getTime();
                    const commands = map.get(timeKey) || [];
                    commands.push(command);
                    map.set(timeKey, commands);
                },
            );
        });
        return Array.from(map.entries())
            .sort((a, b) => a[0] - b[0])
            .map(([time, commands]) => ({ time, commands }));
    }, [templates, startDate, finalDate]);

    const onSubmit = useCallback(() => false, []);

    return (
        <div>
            <div className="mb-6 w-min">
                <form
                    className="isolate -space-x-px rounded-md shadow-sm flex"
                    onSubmit={onSubmit}
                >
                    <div className="relative rounded-md rounded-r-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                        <label
                            htmlFor="start"
                            className="block text-xs font-medium text-gray-900"
                        >
                            Начало
                        </label>
                        <input
                            type="datetime-local"
                            id="start"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            value={startDateInputProps.value}
                            onChange={startDateInputProps.onChange}
                        />
                    </div>
                    <div className="relative rounded-md rounded-l-none px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-indigo-600">
                        <label
                            htmlFor="final"
                            className="block text-xs font-medium text-gray-900"
                        >
                            Окончание
                        </label>
                        <input
                            type="datetime-local"
                            id="final"
                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            value={finalDateInputProps.value}
                            onChange={finalDateInputProps.onChange}
                        />
                    </div>
                </form>
            </div>
            <div className="flex flex-row whitespace-pre font-mono">
                <ul className="w-min mr-2">
                    {rows.map(row => row.commands.length > thresholdWarning ? (
                        <li
                            key={row.time}
                            className={`${row.commands.length > thresholdEmergin ? 'bg-red-200' : 'bg-amber-100'} px-2`}
                        >
                            {row.commands.length}
                        </li>
                    ) : (
                        <li key={row.time} className="bg-slate-100 px-2">&nbsp;</li>
                    ))}
                </ul>
                <ul>
                    {rows.map(row => (
                        <li key={row.time}>
                            {timeFormat(new Date(row.time))}
                            {'\t'}
                            {row.commands.join(', ')}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
