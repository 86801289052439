import type { ChangeEventHandler } from 'react';
import { useCallback, useState } from 'react';
import useDebounceInput from './useDebounceInput';

export default function useDateInput(
    init: Date | (() => Date),
): [
    Date,
    { value: string; onChange: ChangeEventHandler<HTMLInputElement> },
] {
    const [valueDate, setValueDate] = useState<Date>(init);
    const update = useCallback((value: string) => {
        setValueDate(old => {
            const date = new Date(
                // eslint-disable-next-line prefer-template
                Date.parse(value.replace(' ', 'T').padEnd(19, ':00') + '.000Z'),
            );
            return old === date ? old : date;
        });
    }, []);
    const { value, onChange } = useDebounceInput(
        valueDate.toISOString().substring(0, 19).replace('T', ' '),
        update,
    );
    return [valueDate, { value, onChange }];
}
